import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import moment from 'moment';

// Action Types
export const FETCH_START = 'FETCH_START';
export const FETCH_ANALYTICS_COUNTERS_SUCCESS = 'FETCH_ANALYTICS_COUNTERS_SUCCESS';
export const FETCH_ANALYTICS_COUNTERS_FAILURE = 'FETCH_ANALYTICS_COUNTERS_FAILURE';
export const FETCH_ANALYTICS_USERS_SUCCESS = 'FETCH_ANALYTICS_USERS_SUCCESS';
export const FETCH_ANALYTICS_USERS_FAILURE = 'FETCH_ANALYTICS_USERS_FAILURE';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const FETCH_GAMES_FAILURE = 'FETCH_GAMES_FAILURE';
export const FETCH_GAME_USERS_SUCCESS = 'FETCH_GAME_USERS_SUCCESS';
export const FETCH_GAME_USERS_FAILURE = 'FETCH_GAME_USERS_FAILURE';
export const CLEAR_ANALYTICS_DATA = 'CLEAR_ANALYTICS_DATA';
export const FETCH_NEW_BETS_THIS_WEEK_SUCCESS = 'FETCH_NEW_BETS_THIS_WEEK_SUCCESS';
export const FETCH_NEW_BETS_THIS_WEEK_FAILURE = 'FETCH_NEW_BETS_THIS_WEEK_FAILURE';

// CSV Action Types
export const FETCH_ANALYTICS_COUNTERS_CSV_SUCCESS = 'FETCH_ANALYTICS_COUNTERS_CSV_SUCCESS';
export const FETCH_ANALYTICS_COUNTERS_CSV_FAILURE = 'FETCH_ANALYTICS_COUNTERS_CSV_FAILURE';
export const FETCH_ANALYTICS_USERS_CSV_SUCCESS = 'FETCH_ANALYTICS_USERS_CSV_SUCCESS';
export const FETCH_ANALYTICS_USERS_CSV_FAILURE = 'FETCH_ANALYTICS_USERS_CSV_FAILURE';
export const FETCH_GAMES_CSV_SUCCESS = 'FETCH_GAMES_CSV_SUCCESS';
export const FETCH_GAMES_CSV_FAILURE = 'FETCH_GAMES_CSV_FAILURE';

export const FETCH_GAME_USERS_CSV_SUCCESS = 'FETCH_GAME_USERS_CSV_SUCCESS';
export const FETCH_GAME_USERS_CSV_FAILURE = 'FETCH_GAME_USERS_CSV_FAILURE';

export const FETCH_NEW_USERS_THIS_WEEK_SUCCESS = 'FETCH_NEW_USERS_THIS_WEEK_SUCCESS';
export const FETCH_NEW_USERS_THIS_WEEK_FAILURE = 'FETCH_NEW_USERS_THIS_WEEK_FAILURE';
export const SET_GLOBAL_USER_TYPE = 'SET_GLOBAL_USER_TYPE';

export const clearAnalyticsData = () => ({
  type: CLEAR_ANALYTICS_DATA,
});

export const B2BUserType = {
  SPORTWORLD: 6,
  TSC: 7,
  PBC: 9,
};

const API_ENDPOINTS = {
  analyticsCounters: '/challenges/analytics/counters',
  analyticsUsers: '/challenges/analytics/users',
  games: '/challenges/analytics/games',
  gameUsers: (gameId, country) => `/challenges/analytics/games/${gameId}/${country}/users`,
};

// Helper functions
const getAxiosParams = ({ startDate, endDate, externalType }) => {
  const params = { startDate, endDate };
  if (externalType) params.externalType = externalType;
  return params;
};

const handleAxiosResponse = (dispatch, successType, response) => {
  dispatch(fetchSuccess());
  dispatch({
    type: successType,
    payload: response.data,
  });
};

const handleAxiosError = (dispatch, errorType, error) => {
  const errorMessage = (error.response && error.response.data && error.response.data.message) || 'Error fetching data';
  dispatch(fetchError(errorMessage));
  dispatch({
    type: errorType,
    payload: errorMessage,
  });
};

const fetchAnalyticsData = (url, params, successType, failureType) => async dispatch => {
  dispatch(fetchStart());

  try {
    const response = await axios.get(url, { params });
    handleAxiosResponse(dispatch, successType, response);
  } catch (error) {
    handleAxiosError(dispatch, failureType, error);
  }
};

export const fetchAnalyticsCounters = ({ externalType, startDate, endDate }) => {
  return async dispatch => {
    const params = getAxiosParams({ startDate, endDate });

    if (localStorage.getItem('isB2B')) {
      params.externalType = localStorage.getItem('b2bExternalType');
      dispatch(
        fetchAnalyticsData(
          API_ENDPOINTS.analyticsCounters,
          params,
          FETCH_ANALYTICS_COUNTERS_SUCCESS,
          FETCH_ANALYTICS_COUNTERS_FAILURE,
        ),
      );
    } else if (externalType) {
      params.externalType = externalType;
      dispatch(
        fetchAnalyticsData(
          API_ENDPOINTS.analyticsCounters,
          params,
          FETCH_ANALYTICS_COUNTERS_SUCCESS,
          FETCH_ANALYTICS_COUNTERS_FAILURE,
        ),
      );
    } else {
      const types = Object.values(B2BUserType); // SPORTWORLD, TSC, PBC
      const requests = types.map(type =>
        axios.get(API_ENDPOINTS.analyticsCounters, {
          params: { ...params, externalType: type },
        }),
      );

      Promise.all(requests)
        .then(responses => {
          const combinedData = responses.reduce((acc, response) => {
            response.data.forEach(counter => {
              const existingCountryData = acc.find(item => item.country === counter.country);

              if (existingCountryData) {
                existingCountryData.totalUsers += counter.totalUsers;
                existingCountryData.newUsers += counter.newUsers;
                existingCountryData.totalBets += counter.totalBets;
                existingCountryData.newBets += counter.newBets;
              } else {
                acc.push({ ...counter });
              }
            });

            return acc;
          }, []);

          console.log('Combined Data for All External Types:', combinedData);
          dispatch(fetchSuccess());
          dispatch({
            type: FETCH_ANALYTICS_COUNTERS_SUCCESS,
            payload: combinedData,
          });
        })
        .catch(error => handleAxiosError(dispatch, FETCH_ANALYTICS_COUNTERS_FAILURE, error));
    }
  };
};

// Fetch Analytics Users
export const fetchAnalyticsUsers = ({ externalType, startDate, endDate }) => {
  return async dispatch => {
    const params = getAxiosParams({ startDate, endDate });

    if (localStorage.getItem('isB2B')) {
      params.externalType = localStorage.getItem('b2bExternalType');
      dispatch(
        fetchAnalyticsData(
          API_ENDPOINTS.analyticsUsers,
          params,
          FETCH_ANALYTICS_USERS_SUCCESS,
          FETCH_ANALYTICS_USERS_FAILURE,
        ),
      );
    } else if (externalType && externalType !== 'ALL') {
      params.externalType = externalType;
      dispatch(
        fetchAnalyticsData(
          API_ENDPOINTS.analyticsUsers,
          params,
          FETCH_ANALYTICS_USERS_SUCCESS,
          FETCH_ANALYTICS_USERS_FAILURE,
        ),
      );
    } else {
      const types = Object.values(B2BUserType);
      const requests = types.map(type =>
        axios.get(API_ENDPOINTS.analyticsUsers, {
          params: { ...params, externalType: type },
        }),
      );

      Promise.all(requests)
        .then(responses => {
          const combinedData = responses.flatMap(response => response.data);
          dispatch(fetchSuccess());
          dispatch({
            type: FETCH_ANALYTICS_USERS_SUCCESS,
            payload: combinedData,
          });
        })
        .catch(error => handleAxiosError(dispatch, FETCH_ANALYTICS_USERS_FAILURE, error));
    }
  };
};

// Fetch Games
export const fetchGames = ({ externalType, startDate, endDate }) => {
  return async dispatch => {
    const params = getAxiosParams({ startDate, endDate });

    if (localStorage.getItem('isB2B')) {
      params.externalType = localStorage.getItem('b2bExternalType');
      dispatch(fetchAnalyticsData(API_ENDPOINTS.games, params, FETCH_GAMES_SUCCESS, FETCH_GAMES_FAILURE));
    } else if (externalType && externalType !== 'ALL') {
      params.externalType = externalType;
      console.log(externalType, 'externaltype');
      dispatch(fetchAnalyticsData(API_ENDPOINTS.games, params, FETCH_GAMES_SUCCESS, FETCH_GAMES_FAILURE));
    } else {
      const requests = Object.entries(B2BUserType).map(([key, type]) =>
        axios
          .get(API_ENDPOINTS.games, {
            params: { ...params, externalType: type },
          })
          .then(response =>
            response.data.map(game => ({
              ...game,
              externalType: type,
            })),
          ),
      );

      Promise.all(requests)
        .then(responses => {
          const combinedData = responses.flatMap(response => response);
          dispatch(fetchSuccess());
          dispatch({
            type: FETCH_GAMES_SUCCESS,
            payload: combinedData,
          });
        })
        .catch(error => handleAxiosError(dispatch, FETCH_GAMES_FAILURE, error));
    }
  };
};

// Fetch Game Users
export const fetchGameUsers = (gameId, country, externalType, countryBets) => {
  return async dispatch => {
    dispatch(fetchStart());

    if (localStorage.getItem('isB2B')) {
      externalType = localStorage.getItem('b2bExternalType');
    }

    try {
      if (country === 'ALL') {
        const allCountries = countryBets.map(cb => cb.country);

        const requests = allCountries.map(c => axios.get(API_ENDPOINTS.gameUsers(gameId, c), { params: { externalType } }));

        const responses = await Promise.all(requests);

        const combinedUsers = responses.flatMap(response => response.data);

        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_GAME_USERS_SUCCESS,
          payload: { gameId, users: combinedUsers },
        });
      } else {
        const url = API_ENDPOINTS.gameUsers(gameId, country);

        const response = await axios.get(url, { params: { externalType } });

        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_GAME_USERS_SUCCESS,
          payload: { gameId, users: response.data },
        });
      }
    } catch (error) {
      console.error('Error fetching game users:', error);
      handleAxiosError(dispatch, FETCH_GAME_USERS_FAILURE, error);
    }
  };
};

// Utility function to generate and download CSV
const downloadCSV = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Fetch Analytics Counters CSV
export const fetchAnalyticsCountersCSV = () => {
  return (dispatch, getState) => {
    dispatch(fetchStart());

    const { counters } = getState().analytics;

    if (!counters || counters.length === 0) {
      const errorMessage = 'No data available to download.';
      dispatch(fetchError(errorMessage));
      return;
    }

    const csvLines = [
      'Country,Total Users,New Users,Total Bets,New Bets',
      ...counters.map(counter => {
        const userTypeEntries = counter.userType ? Object.entries(counter.userType) : [];
        const userTypeData = userTypeEntries
          .map(([type, data]) => `${type} (${data.totalUsers}/${data.newUsers}/${data.totalBets}/${data.newBets})`)
          .join(' | ');

        return `${counter.country},${counter.totalUsers},${counter.newUsers},${counter.totalBets},${counter.newBets},${userTypeData}`;
      }),
    ].join('\n');

    downloadCSV(csvLines, 'analytics_counters.csv');
    dispatch(fetchSuccess());
  };
};

// Fetch Analytics Users CSV
export const fetchAnalyticsUsersCSV = () => {
  return (dispatch, getState) => {
    dispatch(fetchStart());

    const { users } = getState().analytics;

    if (!users || users.length === 0) {
      const errorMessage = 'No data available to download.';
      dispatch(fetchError(errorMessage));
      return;
    }

    const csvLines = [
      'Email,Nickname,Country,Total Points,Total Bets,Most Recent Bet Date, Creation Date',
      ...users.map(user => {
        const mostRecentBetDate = user.mostRecentBetDate ? new Date(user.mostRecentBetDate).toISOString() : '';
        const userCreatedDate = user.userCreatedAt ? new Date(user.userCreatedAt).toISOString() : '';
        return `${user.email},${user.nickname},${user.country},${user.totalPoints},${user.totalBets},${mostRecentBetDate},${userCreatedDate}`;
      }),
    ].join('\n');

    downloadCSV(csvLines, 'analytics_users.csv');
    dispatch(fetchSuccess());
  };
};

// Fetch Games CSV
export const fetchGamesCSV = () => {
  return (dispatch, getState) => {
    dispatch(fetchStart());

    const { games } = getState().analytics;

    if (!games || games.length === 0) {
      const errorMessage = 'No data available to download.';
      dispatch(fetchError(errorMessage));
      return;
    }

    const allCountryCodes = Array.from(
      new Set(games.flatMap(game => game.countryBets.map(countryBet => countryBet.country))),
    );

    const headers = ['gameHeadline', 'date', 'betsPlaced', ...allCountryCodes];

    const csvLines = [
      headers.join(','),
      ...games.map(game => {
        const countryBetMap = game.countryBets.reduce((acc, { country, bets }) => {
          acc[country] = bets;
          return acc;
        }, {});

        const countryData = allCountryCodes.map(countryCode => countryBetMap[countryCode] || 0);
        const row = [game.gameHeadline || '', game.date || '', game.betsPlaced || 0, ...countryData];

        return row.join(',');
      }),
    ].join('\n');

    downloadCSV(csvLines, 'analytics_games.csv');
    dispatch(fetchSuccess());
  };
};

// Fetch Game Users CSV
export const fetchGameUsersCSV = (gameId, country) => {
  return (dispatch, getState) => {
    dispatch(fetchStart());

    const { gameUsers } = getState().analytics;
    const usersForGame = gameUsers[gameId] || [];

    if (usersForGame.length === 0) {
      const errorMessage = 'No data available to download.';
      dispatch(fetchError(errorMessage));
      return;
    }

    const csvLines = [
      'User ID,Email,Nickname',
      ...usersForGame.map(user => {
        return [user._id, user.email, user.nickname]
          .map(value => {
            if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
          })
          .join(',');
      }),
    ].join('\n');

    downloadCSV(csvLines, `game_users_${gameId}_${country}.csv`);
    dispatch(fetchSuccess());
  };
};

export const fetchNewUsers = ({ country, userType, startDate, endDate }) => {
  return async dispatch => {
    dispatch(fetchStart());

    const params = {
      startDate: startDate,
      endDate: endDate,
    };

    let requests = [];

    if (localStorage.getItem('isB2B')) {
      params.externalType = localStorage.getItem('b2bExternalType');
      requests = [axios.get(API_ENDPOINTS.analyticsCounters, { params })];
    } else {
      if (!userType) {
        const externalTypes = Object.values(B2BUserType);
        requests = externalTypes.map(type =>
          axios.get(API_ENDPOINTS.analyticsCounters, { params: { ...params, externalType: type } }),
        );
      } else {
        requests = [axios.get(API_ENDPOINTS.analyticsCounters, { params: { ...params, externalType: userType } })];
      }
    }

    try {
      const responses = await Promise.all(requests);
      let combinedData = responses.flatMap(response => response.data);

      if (country && country !== 'ALL') {
        combinedData = combinedData.filter(counter => counter.country === country);
      }

      const newUsersThisWeek = combinedData.reduce((sum, counter) => sum + counter.newUsers, 0);

      dispatch(fetchSuccess());
      dispatch({
        type: FETCH_NEW_USERS_THIS_WEEK_SUCCESS,
        payload: newUsersThisWeek,
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error fetching new users for this week.';
      dispatch(fetchError(errorMessage));
      dispatch({
        type: FETCH_NEW_USERS_THIS_WEEK_FAILURE,
        payload: errorMessage,
      });
    }
  };
};

export const fetchNewBets = ({ country, userType, startDate, endDate }) => {
  return async dispatch => {
    dispatch(fetchStart());

    const params = {
      startDate: startDate,
      endDate: endDate,
    };

    let requests = [];

    if (localStorage.getItem('isB2B')) {
      params.externalType = localStorage.getItem('b2bExternalType');
      requests = [axios.get(API_ENDPOINTS.analyticsCounters, { params })];
    } else {
      if (!userType) {
        const externalTypes = Object.values(B2BUserType);
        requests = externalTypes.map(type =>
          axios.get(API_ENDPOINTS.analyticsCounters, { params: { ...params, externalType: type } }),
        );
      } else {
        requests = [axios.get(API_ENDPOINTS.analyticsCounters, { params: { ...params, externalType: userType } })];
      }
    }

    try {
      const responses = await Promise.all(requests);
      let combinedData = responses.flatMap(response => response.data);

      if (country && country !== 'ALL') {
        combinedData = combinedData.filter(counter => counter.country === country);
      }

      const newBetsThisWeek = combinedData.reduce((sum, counter) => sum + counter.newBets, 0);

      dispatch(fetchSuccess());
      dispatch({
        type: FETCH_NEW_BETS_THIS_WEEK_SUCCESS,
        payload: newBetsThisWeek,
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error fetching new bets for this week.';
      dispatch(fetchError(errorMessage));
      dispatch({
        type: FETCH_NEW_BETS_THIS_WEEK_FAILURE,
        payload: errorMessage,
      });
    }
  };
};

export const setGlobalUserType = userType => ({
  type: SET_GLOBAL_USER_TYPE,
  payload: userType,
});
