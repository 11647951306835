import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { setAuthUser, updateLoadUser, updateB2BStatus } from 'redux/actions/Auth';
import axios from './config';
import { jwtDecode } from 'jwt-decode';
import { clearAnalyticsData } from 'redux/actions/Analytics';

axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data.path !== '/authentication/refresh-token'
    ) {
      originalRequest._retry = true;
      axios
        .post('authentication/refresh-token', '', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('login')).refreshToken,
          },
        })
        .then(res => {
          if (res.data) {
            const access_token = res.data.accessToken;
            originalRequest.headers.Authorization = 'Bearer ' + access_token;
            localStorage.setItem('token', access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;

            return axios(originalRequest).then(data => {
              if (
                window.location.href.indexOf('/official-championship/new') !== -1 ||
                window.location.href.indexOf('/official-championship/') !== -1
              ) {
                window.location.href = '/official-championships';
              } else {
                window.location.reload();
              }
            });
          }
        });
    } else if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data.path === '/authentication/refresh-token'
    ) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      localStorage.removeItem('login');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/authentication/signup', {
          email: email,
          password: password,
          username: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.accessToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.accessToken;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.accessToken));
            dispatch(clearAnalyticsData());
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('/authentication/signin', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            const token = data.accessToken;
            if (token) {
              localStorage.setItem('token', token);
              delete data.accessToken;
              localStorage.setItem('login', JSON.stringify(data));

              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              const decodedToken = jwtDecode(token);
              const isB2B = decodedToken.roles.includes('b2bClient');
              const b2bExternalType = data.b2bExternalType;

              if (isB2B) {
                localStorage.setItem('isB2B', true);
                localStorage.setItem('b2bExternalType', b2bExternalType);
                dispatch(updateB2BStatus({ isB2B: true, b2bExternalType }));
              } else {
                localStorage.removeItem('isB2B');
                localStorage.removeItem('b2bExternalType');
                dispatch(updateB2BStatus({ isB2B: false, b2bExternalType: null }));
              }
              dispatch(fetchSuccess());
              dispatch(setAuthUser(data));
              dispatch(clearAnalyticsData());
            } else {
              dispatch(fetchError(data));
            }
          })
          .catch(error => {
            if (error.response && typeof error.response.data === 'object') {
              dispatch(fetchError(error.response.data.message));
            } else {
              dispatch(fetchError(error));
            }
          });
      } catch (error) {
        dispatch(fetchError(error));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/authentication/signout', '', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then(({ data }) => {
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          localStorage.removeItem('login');
          localStorage.removeItem('isB2B');
          localStorage.removeItem('b2bExternalType');

          dispatch(setAuthUser(null));
          dispatch(updateB2BStatus({ isB2B: false, b2bExternalType: null }));
          dispatch(clearAnalyticsData());
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      if (!token) {
        dispatch(updateLoadUser(true));
        return false;
      }
      axios
        .post('/authentication/refresh-token')
        .then(({ data }) => {
          if (data.authToken) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(error => {
          dispatch(updateLoadUser(true));
        });
    };
  },
};

export default JWTAuth;
